<template>
  <div class="d-flex flex-row">
    <v-text-field color="red" class="pr-4" dense outlined label="Pseudo" v-on:keyup.enter="savePlayerName()" v-model="playerName" :rules="rules"></v-text-field>
    <v-btn elevation="0" color="red" class="white--text" @click="savePlayerName()">Enregistrer</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
        type: String,
        default: ''
    }
  },
  data() {
    return {
        playerName: "",
        rules: [v => !!v || 'Le pseudo est obligatoire', v => (v && v.length <= 20) || 'Le pseudo doit comporter au maximum 20 caractères']
    };
  },
  created() {
    this.playerName = localStorage.getItem("playerName") || "";
    this.$emit('input', this.playerName)
  },
  methods: {
    savePlayerName() {
      if(this.playerName) {
        this.$emit('input', this.playerName)
        localStorage.setItem("playerName", this.playerName);
      }
    },
  },
};
</script>
