import Phaser from 'phaser'
import MongoClient from "@/services/MongoClient";

export default class gameEnd extends Phaser.Scene {
    constructor() {
        super({ key: 'endGame' });
    }
    async init(data) {
        this.score = data.scoreF;
        if(this.score > 0) {
            try {
                await MongoClient.insertScore({name: localStorage.getItem("playerName"), score: this.score, elapsedTime: data.elapsedTime})
            } catch (error) {
                console.log(error)
            }
        }
    }
    preload() {
        this.load.image('ballon', 'img/img/ballon.png');
        this.load.image('pieds', 'img/img/betshot-pieds.png');
    }
    create() {
        this.ballon = this.add.image(1080 / 2, 1920 / 2 + 200, 'ballon');
        this.pieds = this.add.image(1080 / 2, 1920 / 2 + 350, 'pieds');

        this.text = this.add.text(1080 / 2, 1920 / 2 - 500, "FELICITATION !").setStyle({
            fontFamily: 'Helvetica Neue LT Std',
            color: '#EBEBEB',
            align: 'center',
            fontStyle: 'bold'
        }).setFontSize(100).setOrigin(0.5);

        this.text = this.add.text(1080 / 2, 1920 / 2 - 300, 'SCORE: ' + this.score).setStyle({
            fontFamily: 'Helvetica Neue LT Std',
            color: '#EBEBEB',
            align: 'center',
            fontStyle: 'bold'
        }).setFontSize(160).setOrigin(0.5);
        
        this.playButton = this.add.rectangle(1080 / 2, 1600, 400, 120, 0x000000, 0)
            .setStrokeStyle(5, 0xffffff)
            .setInteractive();
        this.playButtonText = this.add.text(1080 / 2, 1600, "REJOUER", {
            fontFamily: 'Helvetica Neue LT Std',
            color: '#EBEBEB',
            align: 'center',
            fontStyle: 'bold',
        }).setOrigin(0.5).setFontSize(60);

        this.playButton.on('pointerdown', () => {
            this.scene.get('BootGame').resetAnimations();
            this.scene.switch('BootGame');
        });       

        var tweendata = {
            targets: this.ballon,
            y: 1140,
            duration: 1200,
            ease: 'Linear',
            autoStart: true,
            delay: 0,
            repeat: 1000,
            yoyo: true,
        }

        this.tw = this.add.tween(tweendata);
    }
    update() {}
}