<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="3" class="d-none d-md-block">
            <!-- Espace vide à gauche pour le profil -->
          </v-col>
          <v-col cols="12" md="6" class="game-container" :class="{ h100: playerName }">
            <!-- Ici, ajoute ton composant de jeu Phaser -->
            <BetBalloon v-if="playerName" />
            <p v-else>Entrer un pseudo avant de jouer</p>
            <v-btn
              v-if="$vuetify.breakpoint.smAndDown"
              fixed
              fab
              bottom
              right
              small
              color="red"
              @click="scrollToScoreBoard"
            >
              <v-icon color="white">mdi-format-list-numbered</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="3" class="score-container">
            <EnterName v-model="playerName" />
            <ScoreBoard />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ScoreBoard from "./components/ScoreBoard.vue";
import BetBalloon from "./components/BetBalloon.vue";
import EnterName from "./components/EnterName.vue";

export default {
  components: {
    ScoreBoard,
    BetBalloon,
    EnterName,
  },
  data() {
    return {
      playerName: ""
    };
  },
  created() {
    console.log("      .--..--..--..--..--..--.");
    console.log("    .' \\  (`._   (_)     _   \\");
    console.log("  .'    |  '._)         (_)  |");
    console.log("  \\ _.')\\      .----..---.   /");
    console.log("  |(_.'  |    /    .-\\-.  \\  |");
    console.log("  \\     0|    |   ( O| O) | o|");
    console.log("   |  _  |  .--.____.'._.-.  |");
    console.log("   \\ (_) | o         -` .-`  |");
    console.log("    |    \\   |`-._ _ _ _ _\\ /");
    console.log("    \\    |   |  `. |_||_|   |");
    console.log("    | o  |    \\_      \\     |     -.   .-.");
    console.log("    |.-.  \\     `--..-'   O |     `.`-' .'");
    console.log("  _.'  .' |     `-.-'      /-.__   ' .-'");
    console.log(".' `-.` '.|='=.='=.='=.='=|._/_ `-'.'");
    console.log("`-._  `.  |________/\\_____|    `-.'");
    console.log("   .'   ).| '=' '='\\/ '=' |");
    console.log("   `._.`  '---------------'");
    console.log("           //___\\   //___\\");
    console.log("             ||       ||");
    console.log("             ||_.-.   ||_.-.");
    console.log("            (_.--__) (_.--__) ");
    console.log("              KEVIN GOSSET");
  },
  methods: {
    scrollToScoreBoard() {
      const scoreBoard = this.$el.querySelector(".score-container");
      if (scoreBoard) {
        scoreBoard.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style>
.h100 {
  height: 100vh;
}
.game-container {
  display: grid;
  place-items: center;
}
</style>
