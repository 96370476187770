import Phaser from 'phaser'

export default class gameMenu extends Phaser.Scene {
    constructor() {
        super({ key: 'BootGame' });
    }
    preload() {
        this.load.image('ballon', 'img/img/ballon.png');
        this.load.image("cadeau", "img/img/cadeau.png");
    }
    create() {
        this.playButton = this.add.rectangle(1080 / 2, 1920 / 2 + 50, 400, 120, 0x000000, 0)
            .setStrokeStyle(5, 0xffffff)
            .setInteractive();
        this.playButtonText = this.add.text(1080 / 2, 1920 / 2 + 50, "JOUER", {
            fontFamily: 'Helvetica Neue LT Std',
            color: '#EBEBEB',
            align: 'center',
            fontStyle: 'bold',
        }).setOrigin(0.5).setFontSize(80);

        this.playButton.on('pointerdown', () => {
            this.playButtonText.visible = false;
            this.playButton.visible = false;
            this.anim = true;
        });

        this.ballon = this.add.image(1080 / 2, 1920 / 2 - 150, 'ballon');

        this.cadeau = this.add.image(1080 / 2, 1920 / 2 + 600, 'cadeau');

        var tweendata = {
            targets: this.ballon,
            y: 700,
            duration: 900,
            ease: 'Linear',
            autoStart: true,
            delay: 0,
            repeat: 1000,
            yoyo: true,
        }

        this.tw = this.add.tween(tweendata);
    }
    update() {
        if (this.anim == true) {
            this.tw.stop();
            if (this.ballon.y < this.cadeau.y - 190) {
                this.ballon.y += 12;
                if (this.ballon.y > this.cadeau.y - 191) {
                    this.anim = false;
                    this.anim2 = true;
                }
            }
        }

        if (this.anim2 == true) {
            if (this.ballon.y > 1920 / 2 - 150) {
                this.ballon.y -= 12;
                this.cadeau.y -= 12;
            } else {
                this.scene.start('playGame');
            }
        }
    }

    resetAnimations() {
        this.playButtonText.visible = true;
        this.ballon.y = 1920 / 2 - 150;
        this.cadeau.y = 1920 / 2 + 600;
        this.anim = false;
        this.anim2 = false;
      
        var tweendata = {
            targets: this.ballon,
            y: 700,
            duration: 900,
            ease: 'Linear',
            autoStart: true,
            delay: 0,
            repeat: 1000,
            yoyo: true,
        }
      
        this.tw = this.add.tween(tweendata);
      }      
}