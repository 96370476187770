import * as Realm from 'realm-web'

const API_KEY = process.env.VUE_APP_MONGO_API_KEY
const REALM_APP_ID = process.env.VUE_APP_MONGO_REALM_APP_ID

export const app = new Realm.App({ id: REALM_APP_ID })

async function loginApiKey (apiKey) {
  const credentials = Realm.Credentials.apiKey(apiKey)
  const user = await app.logIn(credentials)
  console.assert(user.id === app.currentUser.id)
  return user
}
class AtlasClient {
    _instance = null

    async init () {
      this._instance = await loginApiKey(API_KEY)
    }

    async getInstance () {
      if (!this._instance) {
        await this.init()
      }
      return this._instance
    }

    async getRanks () {
      const instance = await this.getInstance()
      const data = await instance.functions.getRanks()
      return data
    }

    async insertScore (score) {
        const instance = await this.getInstance()
        const data = await instance.functions.insertScore(score)
        return data
    }

    async watchTickets () {
      const instance = await this.getInstance()
      const collection = instance.mongoClient('mongodb-atlas').db('betballoon').collection(process.env.VUE_APP_MONGO_COLLECTION)
      return await collection.watch()
      // return await collection.watch([
      //   {
      //     $match: {
      //       operationType: { $in: ['insert', 'update'] }
      //     }
      //   }
      // ])
    }
}

export default new AtlasClient()