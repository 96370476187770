<template>
  <ion-phaser v-bind:game.prop="game" v-bind:initialize.prop="initialize" />
</template>

<script>
import Phaser from "phaser";
import PhaserMatterCollisionPlugin from "phaser-matter-collision-plugin";
import gameMenu from '@/scene/gameMenu.js'
import gameMain from '@/scene/gameMain.js'
import gameEnd from '@/scene/gameEnd.js'

export default {
  name: 'BetBalloon',
  data() {
    return {
      initialize: true,
      game: {
        type: Phaser.AUTO,
        backgroundColor: 0xfe595e,
        scale: {
          mode: Phaser.Scale.FIT,
          autoCenter: Phaser.Scale.CENTER_BOTH,
          width: 1080,
          height: 1920,
        },
        pixelArt: true,
        physics: {
          default: "matter",
          matter: {
            gravity: {
              y: 4,
            },
            // debug: true
          },
        },
        plugins: {
          scene: [
            {
              plugin: PhaserMatterCollisionPlugin,
              key: "matterCollision",
              mapping: "matterCollision",
            },
          ],
        },
        scene: [gameMenu, gameMain, gameEnd],
      },
    };
  }
};
</script>