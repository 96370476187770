// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'mdi', // Utilise les icônes Material Design Icons
      },
    theme: {
        themes: {
          light: {
            red: '#fe595e',
          },
          dark: {
            red: '#fe595e',
          },
        },
      },
}

export default new Vuetify(opts)