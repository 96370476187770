<template>
  <v-card outlined elevation="0">
    <v-card-title>Classement Top 10</v-card-title>
    <v-list shaped disabled>
      <v-list-item-group v-model="selectedItem" color="red">
        <v-list-item v-for="(item, i) in sortedRanks" :key="i">
          <v-list-item-content>
            <v-list-item-title class="text-left d-flex">
              <div class="mr-auto">#{{ i + 1 }} {{ item.name }} - {{ item.score }} ({{ item.count }} essai)</div>
              <v-chip class="ml-2" v-if="selectedItem === i" small outlined color="red">Nouveau score</v-chip>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-card-text>Crée par Kevin Gosset pour Betshot.fr</v-card-text>
  </v-card>
</template>

<script>
import MongoClient from "@/services/MongoClient";
export default {
  name: "ScoreBoard",
  data() {
    return {
      ranks: [],
      selectedItem: null,
    };
  },
  computed: {
    sortedRanks() {
      const ranksByName = {};
      this.ranks.forEach((item) => {
        if (!ranksByName[item.name] || ranksByName[item.name].score < item.score) {
          ranksByName[item.name] = item;
        }
      });

      const sorted = Object.values(ranksByName).sort((a, b) => b.score - a.score);
      return sorted.slice(0, 10);
    },
  },
  methods: {
    async getRanks() {
      try {
        const response = await MongoClient.getRanks();
        this.ranks = response.result;
      } catch (error) {
        console.log(error);
      }
    },
    async watchDB() {
      try {
        const response = await MongoClient.watchTickets();
        for await (const change of response) {
          this.ranks.push(change.fullDocument);

          const sortedIndex = this.sortedRanks.findIndex(
            (item) => item._id === change.fullDocument._id
          );

          this.selectedItem = sortedIndex;
          setTimeout(() => {
            this.selectedItem = null;
          }, 10000);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getRanks();
    this.watchDB();
  },
};
</script>
