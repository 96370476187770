import Phaser from 'phaser'

const gameOptions = {
    ballonFlapPower: 15,

    maxHaut: 100,
    minBas: 1820,
    minMilieu: 900,
    maxMilieu: 1000,

    speed: 10
}

export default class gameMain extends Phaser.Scene {
    constructor() {
        super('playGame');
    }
    preload() {
        this.load.atlas('nuage1sheet', 'img/assets/nuage1-sprite.png', 'img/assets/nuage1-sprite.json');
        this.load.atlas('nuage2sheet', 'img/assets/nuage2-sprite.png', 'img/assets/nuage2-sprite.json');
        this.load.atlas('nuage3sheet', 'img/assets/nuage3-sprite.png', 'img/assets/nuage3-sprite.json');
        this.load.atlas('ballonsheet', 'img/assets/ballon-sprite.png', 'img/assets/ballon-sprite.json');

        this.load.json('nuage1shape', 'img/assets/nuage1-shape.json');
        this.load.json('nuage2shape', 'img/assets/nuage2-shape.json');
        this.load.json('nuage3shape', 'img/assets/nuage3-shape.json');
        this.load.json('ballonshape', 'img/assets/ballon-shape.json');
    }

    create() {
        this.startTime = this.time.now;
        this.elapsedTime = 0
        gameOptions.speed = 10

        var nuage1shape = this.cache.json.get('nuage1shape');
        var nuage2shape = this.cache.json.get('nuage2shape');
        var nuage3shape = this.cache.json.get('nuage3shape');
        var ballonshape = this.cache.json.get('ballonshape');

        this.input.on('pointerdown', this.flap, this);

        this.randomNuageHaut = Phaser.Math.Between(1, 3);
        this.randomNuageBas = Phaser.Math.Between(1, 3);
        this.randomNuageMilieu = Phaser.Math.Between(1, 3);

        this.nuageHaut1 = this.matter.add.sprite(Phaser.Math.Between(1700, 1900), Phaser.Math.Between(0, gameOptions.maxHaut), 'nuage1sheet', 'nuageHaut1', { shape: nuage1shape.nuage1 });
        this.nuageHaut2 = this.matter.add.sprite(Phaser.Math.Between(1700, 1900), Phaser.Math.Between(0, gameOptions.maxHaut), 'nuage2sheet', 'nuageHaut2', { shape: nuage2shape.nuage2 });
        this.nuageHaut3 = this.matter.add.sprite(Phaser.Math.Between(1700, 1900), Phaser.Math.Between(0, gameOptions.maxHaut), 'nuage3sheet', 'nuageHaut3', { shape: nuage3shape.nuage3 });

        this.nuageBas1 = this.matter.add.sprite(Phaser.Math.Between(1700, 1900), Phaser.Math.Between(gameOptions.minBas, 1920), 'nuage1sheet', 'nuageBas1', { shape: nuage1shape.nuage1 });
        this.nuageBas2 = this.matter.add.sprite(Phaser.Math.Between(1700, 1900), Phaser.Math.Between(gameOptions.minBas, 1920), 'nuage2sheet', 'nuageBas2', { shape: nuage2shape.nuage2 });
        this.nuageBas3 = this.matter.add.sprite(Phaser.Math.Between(1700, 1900), Phaser.Math.Between(gameOptions.minBas, 1920), 'nuage3sheet', 'nuageHaut3', { shape: nuage3shape.nuage3 });
     
        this.nuageMilieu1 = this.matter.add.sprite(Phaser.Math.Between(2900, 3100), Phaser.Math.Between(gameOptions.minMilieu, gameOptions.maxMilieu), 'nuage1sheet', 'nuageMilieu1', { shape: nuage1shape.nuage1 }).setScale(0.6);
        this.nuageMilieu2 = this.matter.add.sprite(Phaser.Math.Between(2900, 3100), Phaser.Math.Between(gameOptions.minMilieu, gameOptions.maxMilieu), 'nuage2sheet', 'nuageMilieu2', { shape: nuage2shape.nuage2 }).setScale(0.6);
        this.nuageMilieu3 = this.matter.add.sprite(Phaser.Math.Between(2900, 3100), Phaser.Math.Between(gameOptions.minMilieu, gameOptions.maxMilieu), 'nuage3sheet', 'nuageMilieu3', { shape: nuage3shape.nuage3 }).setScale(0.6);

        this.ballon = this.matter.add.sprite(1080 / 2, 1920 / 2, 'ballonsheet', 'ballon', { shape: ballonshape.balloncadeau });

        this.afficheScore = this.add.text(1080 - 270, 60, '').setStyle({ fontFamily: 'Helvetica Neue LT Std', color: '#EBEBEB', align: 'left', fontStyle: 'normal' }).setFontSize(85);
        this.score = 0;

        this.matterCollision.addOnCollideStart({
            objectA: [this.nuageHaut1, this.nuageHaut2, this.nuageHaut3, this.nuageBas1, this.nuageBas2, this.nuageBas3, this.nuageMilieu1, this.nuageMilieu2, this.nuageMilieu3],
            objectB: this.ballon,
            callback: () => {
                this.die();
            }
        });

    }

    update() {
        this.elapsedTime = this.time.now - this.startTime;
        // const maxPossibleScore = Math.floor(this.elapsedTime / 16.67)

        // if (this.score > maxPossibleScore) {
        //     this.die()
        // }

        if (this.score % 200 === 0 && this.score !== 0) {
            gameOptions.speed += 1;
        }

        switch (this.randomNuageHaut) {
            case 1:
                this.moveNuageHaut(this.nuageHaut1, gameOptions.speed);
                break;
            case 2:
                this.moveNuageHaut(this.nuageHaut2, gameOptions.speed);
                break;
            case 3:
                this.moveNuageHaut(this.nuageHaut3, gameOptions.speed);
                break;
            default:
                break;
        }

        switch (this.randomNuageBas) {
            case 1:
                this.moveNuageBas(this.nuageBas1, gameOptions.speed);
                break;
            case 2:
                this.moveNuageBas(this.nuageBas2, gameOptions.speed);
                break;
            case 3:
                this.moveNuageBas(this.nuageBas3, gameOptions.speed);
                break;
            default:
                break;
        }

        switch (this.randomNuageMilieu) {
            case 1:
                this.moveNuageMilieu(this.nuageMilieu1, gameOptions.speed);
                break;
            case 2:
                this.moveNuageMilieu(this.nuageMilieu2, gameOptions.speed);
                break;
            case 3:
                this.moveNuageMilieu(this.nuageMilieu3, gameOptions.speed);
                break;
            default:
                break;
        }

        this.score += 1;
        this.afficheScore.setText(this.score);

        if (this.ballon.y > 1920 || this.ballon.y < 0) {
            this.die();
        }
    }
    moveNuageHaut(nuage, speed) {
        nuage.x -= speed;
        if (nuage.x <= -1300) {
            this.resetNuagePosHaut(nuage);
            this.randomNuageHaut = Phaser.Math.Between(1, 3);
        }
    }
    resetNuagePosHaut(nuage) {
        nuage.x = Phaser.Math.Between(1700, 1900);
        nuage.y = Phaser.Math.Between(0, gameOptions.maxHaut);
    }

    moveNuageBas(nuage, speed) {
        nuage.x -= speed;
        if (nuage.x <= -1300) {
            this.resetNuagePosBas(nuage);
            this.randomNuageBas = Phaser.Math.Between(1, 3);
        }
    }
    resetNuagePosBas(nuage) {
        nuage.x = Phaser.Math.Between(1700, 1900);
        nuage.y = Phaser.Math.Between(gameOptions.minBas, 1920);
    }

    moveNuageMilieu(nuage, speed) {
        nuage.x -= speed;
        if (nuage.x <= -100) {
            this.resetNuagePosMilieu(nuage);
            this.randomNuageMilieu = Phaser.Math.Between(1, 3);
        }
    }
    resetNuagePosMilieu(nuage) {
        nuage.x = Phaser.Math.Between(2900, 3100);
        nuage.y = Phaser.Math.Between(gameOptions.minMilieu, gameOptions.maxMilieu);
    }

    flap() {
        this.ballon.setVelocity(0, -gameOptions.ballonFlapPower);
    }
    die() {
        this.scene.start('endGame', { scoreF: this.score, elapsedTime: this.elapsedTime });
    }
}